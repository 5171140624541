import React from "react";
import styled from "styled-components";
import PokojeIMG from "../../assets/pokoje.jpg";
import Photo1 from "../../assets/pokoje/15.jpg";
import Photo2 from "../../assets/pokoje/21.jpg";
import Photo3 from "../../assets/pokoje/22.jpg";

import PriceSection from "../CennikComponents/PriceSection";
import PokojeSection from "../PokojeComponents/PokojeSection";
import { AiOutlinePhone } from "react-icons/ai";
import { HiOutlinePhotograph } from "react-icons/hi";
import { Link } from "gatsby";
const MainSection = () => {
  return (
    <>
      <Wrapper>
        <Header>
          <h1 className="text">Noclegi</h1>
        </Header>
        <StickyBar>
          <p>
            <Link to="/galeria">
              <HiOutlinePhotograph className="gallery_icon" />
              Galeria
            </Link>
          </p>
          <p className="contact">
            <Link to="/kontakt" className="contact">
              <AiOutlinePhone />
              Kontakt
            </Link>
          </p>
        </StickyBar>
        <PokojeSection />

        <div className="container">
          <div className="box_container-left">
            <h3>ZAKWATEROWANIE</h3>
            <p>
              Dwupiętrowy obiekt hotelarski „Stragona” powstał po
              odrestaurowaniu i dostosowaniu obiektu z biurowca należącego
              niegdyś do Strzegomskich Zakładów Mechanicznych „Zremb”.
              Dysponujemy 60 miejscami noclegowymi w budynku głównym. W wyniku
              rozwoju obiektu otwarty został również sąsiadujący budynek
              przystosowany do obsługi 40 osób, w tym także osób
              niepełnosprawnych.
            </p>
            <div className="photos">
              <img src={Photo1} alt="" />
              <img src={Photo2} alt="" />
              <img src={Photo3} alt="" />
            </div>
            <p>
              Do dyspozycji gości przeznaczone są pokoje 1, 2, 3 i 4 osobowe, w
              tym także apartamenty wyposażone w łoża małżeńskie i wanny z
              hydromasażem. W przypadku pobytu z dziećmi istnieje możliwość
              dołączenia tzw. „dostawki” do poszczególnych pokoi. Część pokoi
              wyposażona jest w łazienkę, w wypadku pobytu gości w pokojach o
              niższym standardzie, w których nie ma łazienek do dyspozycji gości
              pozostają łazienki ogólnego użytku usytuowane na piętrach. W
              każdym z pokoi goście mogą korzystać z telewizji, a także
              bezprzewodowego Internetu. Istotnym atutem obiektu jest monitoring
              wpływający na podwyższenie bezpieczeństwa gości znajdujących się w
              obiekcie.
            </p>
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2512.59840394827!2d16.348125415750182!3d50.96812887955015!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470fa976dc4a9f39%3A0xaf22f7efa71c63df!2sRestauracja%20Stragona!5e0!3m2!1spl!2spl!4v1640283318883!5m2!1spl!2spl"
              width="100%"
              height="450"
              style={{ border: "none" }}
              allowfullscreen=""
              loading="lazy"
            ></iframe>
            <h3 style={{ textTransform: "uppercase" }}>Ośrodki narciarskie:</h3>
            <ul>
              <li>Superstacja CZARNÓW – SKI – 46km</li>
              <li>Zieleniec – 57km</li>
              <li>Winterpol Biały Jar – 67km</li>
              <li>Ski Arena Szrenica – 75km</li>
              <li>SKI & SUN Świeradów Zdrój – 88km</li>
              <li>Czarna Góra – 111km</li>
            </ul>
            <h3 style={{ textTransform: "uppercase" }}>
              BASENY I CENTRA SPORTOWE:
            </h3>
            <ul>
              <li>Ośrodek Sportu i Rekreacji w Strzegomiu – 1,5km</li>
              <li>Gminne Centrum Kultury i Sportu w Żarowie – 14km</li>
              <li>Ośrodek Sportu i Rekreacji w Świdnicy – 19km</li>
              <li>Basen OsiR w Świebodzicach – 21km</li>
              <li>Aqua Zdrój w Wałbrzychu – 34km</li>
              <li>
                Słowianka. Pływalnia Ośrodka Sportu i Rekreacji w Jaworze – 37km
              </li>
            </ul>
            <h3 style={{ textTransform: "uppercase" }}>Szczyty górskie:</h3>
            <ul>
              <li>Góra Krzyżowa – 2km</li>
              <li>Chełmiec – 22km</li>
              <li>Ślęża – 35km</li>
              <li>Szrenica – 79km</li>
              <li>Śnieżka – 79km</li>
              <li>Szczeliniec Wielki – 83km</li>
              <li>Orlica – 114km</li>
            </ul>
          </div>
          <div className="box_container-right">
            <h3 style={{ textTransform: "uppercase" }}>
              Gdzie znajduje się nasz hotel i restauracja?
            </h3>
            <p>
              Świetna lokalizacja naszego hotelu zachęca do zwiedzania
              okolicznych zabytków, odkrywania nieznanych miejsc, obcowania z
              naturą i uprawiania ulubionych sportów!
            </p>
            <h3 style={{ textTransform: "uppercase" }}>
              Atrakcje turystyczne i zabytki:
            </h3>
            <ul>
              <li>
                Bazylika Kolegiacka pw. św. Apostołów Piotra i Pawła – 450m
              </li>
              <li>Muzeum Gross-Rosen w Rogoźnicy – 8km</li>
              <li>Muzeum Kolejnictwa na Śląsku w Jaworzynie Śląskiej – 11km</li>
              <li>Palmiarnia – 19km</li>
              <li>Zamek Książ – 20km</li>
              <li>Zamek Bolków – 22km</li>
              <li>Stara Kopalnia – 27km</li>
              <li>Zamek Grodno – 33km</li>
              <li>Zamek w Niemczy – 33km</li>
              <li>Park Krajobrazowy Sudety Wałbrzyskie – 35km</li>
              <li>
                Podziemna Trasa Edukacyjna po nieczynnej kopalni Niklu,
                Chryzoprazu i Opalu w Szklarach Huta – 37km
              </li>
              <li>Kolorowe Jeziorka – 38km</li>
              <li>Park Linowy "Wieżyca" – 41km</li>
              <li>Sztolnie Walimskie "RIESE" – 42km</li>
              <li>Podziemne Miasto Osówka – 45km</li>
              <li>Rudawski Park Krajobrazowy – 46km</li>
              <li>Twierdza Srebrna Góra – 46km</li>
              <li>Fabryka Bombek Choinkowych – 47km</li>
              <li>Rezerwat przyrody Głazy Krasnoludków – 48km</li>
              <li>Pałac Marianny Orańskiej w Kamieńcu Ząbkowickim – 51km</li>
              <li>Piernikarnia Śląska – 51km</li>
              <li>Arboretum Wojsławice – 52km</li>
              <li>Zamek Legend Śląskich – 69km</li>
              <li>Minieuroland – 56km</li>
              <li>Zamek Grodziec – 59km</li>
              <li>Hydropolis – 59km</li>
              <li>Kopalnia Złota w Złotym Stoku – 60km</li>
              <li>Park Miniatur Zabytków Dolnego Śląska w Kowarach – 63km</li>
              <li>Szwajcaria Lwówecka - Lwóweckie Skałki – 66km</li>
              <li>Zamek Chojnik – 79km</li>
              <li>Zamek Czocha – 93km</li>
              <li>Zamek Książąt Oleśnickich – 93km</li>
              <li>Piaski Kaolin – 97km</li>
            </ul>
          </div>
        </div>

        {/* <PriceSection /> */}
      </Wrapper>
    </>
  );
};

const StickyBar = styled.div`
  width: 100vw;
  height: 60px;
  z-index: 12;
  box-shadow: 0px 1px 0px 0px rgb(34 44 43 / 15%);
  display: flex;
  justify-content: center;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 61px;
  left: 0;
  background-color: #fff;

  p {
    text-transform: uppercase;
    font-size: 13px;
    color: #919695;
    margin: 10px;
    &.contact {
      color: #009500;
    }

    a {
      display: flex;
      align-items: center;
      text-decoration: none;
      color: #919695;
      &.contact {
        color: #009500;
      }
      svg {
        margin: 3px;
      }
    }
    cursor: pointer;
  }
`;

const Wrapper = styled.section`
  ul {
    li {
      margin-top: 15px;
      color: #495453;
      line-height: 1.75;
      font-weight: 200;
    }
  }
  width: 100vw;
  min-height: 90vh;
  .photos {
    img {
      width: 280px;
      margin: 10px;
    }
  }
  h3 {
    color: #495453;
  }
  p {
    color: #495453;
    line-height: 1.75;
    font-weight: 200;
  }
  .container {
    min-height: 90vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 1024px) {
      flex-direction: column;
    }
  }
  .box_container-left {
    @media (min-width: 1024px) {
      align-self: flex-start;
    }
    padding: 35px;
    width: 60vw;
    @media (max-width: 1024px) {
      padding: 10px;
      width: 90vw;
    }
  }
  .box_container-right {
    @media (min-width: 1024px) {
      align-self: flex-start;
    }
    padding: 35px;
    width: 40vw;
    @media (max-width: 1024px) {
      padding: 10px;
      width: 90vw;
    }
  }
`;

const Header = styled.div`
  height: 55vh;
  background-image: url(${PokojeIMG});
  background-position: center;
  position: relative;
  background-size: cover;
  .text {
    width: 440px;
    position: absolute;
    bottom: 5vh;
    text-align: center;
    font-family: "Josefin Slab", serif;
    font-weight: 200;
    font-size: 80px;
    left: calc(50% - 220px);
    color: white;
    @media (max-width: 1024px) {
      font-size: 46px;
      width: 320px;
      left: calc(50% - 160px);
    }
  }
`;

export default MainSection;
