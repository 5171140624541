import React from "react";
import styled from "styled-components";
import PokojeIMG from "../../assets/pokoje.jpg";
// import Pokoj1 from "../../assets/hotel_room1.jpg";
import Pokoj1 from "../../assets/pokoje/1.jpg";
import Pokoj2 from "../../assets/pokoje/2.jpg";
import Pokoj3 from "../../assets/pokoje/3.jpg";
import { GiTable, GiTowel } from "react-icons/gi";
import { FaShower } from "react-icons/fa";
import { RiArchiveDrawerFill } from "react-icons/ri";
import { MdMonitor } from "react-icons/md";
import { Fade as FadeSlide } from "react-slideshow-image";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import Fade from "react-reveal/Fade";

const MainSection = () => {
  const FadeSlideImages = [Pokoj1, Pokoj2, Pokoj3];
  return (
    <Wrapper>
      <div className="container">
        <Fade>
          <div className="text_container">
            <h2>Zobacz nasze pokoje i zapoznaj sie z oferta.</h2>
          </div>
        </Fade>

        <RoomBox>
          <div className="left-col">
            <div>
              <div className="slide-container" autoplay={false}>
                <FadeSlide>
                  <div className="each-FadeSlide" autoplay={false}>
                    <div>
                      <StaticImage
                        src="../../assets/pokoje2/4_1.jpg"
                        alt="pokoj"
                      />
                    </div>
                  </div>
                  <div className="each-FadeSlide" autoplay={false}>
                    <div>
                      <StaticImage
                        src="../../assets/pokoje2/4_2.jpg"
                        alt="pokoj"
                      />
                    </div>
                  </div>
                  <div className="each-FadeSlide" autoplay={false}>
                    <div>
                      <StaticImage
                        src="../../assets/pokoje2/4_3.jpg"
                        alt="pokoj"
                      />
                    </div>
                  </div>
                  <div className="each-FadeSlide" autoplay={false}>
                    <div>
                      <StaticImage
                        src="../../assets/pokoje2/4_4.jpg"
                        alt="pokoj"
                      />
                    </div>
                  </div>
                </FadeSlide>
              </div>
            </div>
          </div>
          <div className="right-col">
            <h3>Pokój z łazienką podwyższony standard</h3>

            <h3>Cena:</h3>
            <div className="items">
              <li>
                Jednoosobowy-{" "}
                <strong style={{ color: "#495453" }}>130zł/doba</strong>
              </li>
              <li>
                Dwuosobowy-{" "}
                <strong style={{ color: "#495453" }}>230zł/doba</strong>
              </li>
              <li>
                Trzyosobowy-{" "}
                <strong style={{ color: "#495453" }}>330zł/doba</strong>
              </li>
              <li>
                Czteroosobowy-{" "}
                <strong style={{ color: "#495453" }}>430zł/doba</strong>{" "}
              </li>
            </div>
          </div>
        </RoomBox>
        <RoomBox>
          <div className="left-col">
            <div>
              <div className="slide-container" autoplay={false}>
                <FadeSlide>
                  <div className="each-FadeSlide" autoplay={false}>
                    <div>
                      <StaticImage
                        src="../../assets/pokoje2/3_1.jpg"
                        alt="pokoj"
                      />
                    </div>
                  </div>
                  <div className="each-FadeSlide" autoplay={false}>
                    <div>
                      <StaticImage
                        src="../../assets/pokoje2/3_2.jpg"
                        alt="pokoj"
                      />
                    </div>
                  </div>
                  <div className="each-FadeSlide" autoplay={false}>
                    <div>
                      <StaticImage
                        src="../../assets/pokoje2/3_3.jpg"
                        alt="pokoj"
                      />
                    </div>
                  </div>
                </FadeSlide>
              </div>
            </div>
          </div>
          <div className="right-col">
            <h3>Pokój z łazienką standard</h3>

            <h3>Cena:</h3>
            <div className="items">
              <li>
                Jednoosobowy-{" "}
                <strong style={{ color: "#495453" }}>110zł/doba</strong>
              </li>
              <li>
                Dwuosobowy-{" "}
                <strong style={{ color: "#495453" }}>200zł/doba</strong>
              </li>
              <li>
                Trzyosobowy-{" "}
                <strong style={{ color: "#495453" }}>300zł/doba</strong>
              </li>
            </div>
          </div>
        </RoomBox>
        <RoomBox>
          <div className="left-col">
            <div>
              <div className="slide-container" autoplay={false}>
                <div>
                  <StaticImage src="../../assets/pokoje2/2.jpg" alt="pokoj" />
                </div>
              </div>
            </div>
          </div>
          <div className="right-col">
            <h3>Pokój z łazienką budżetowy</h3>

            <h3>Cena:</h3>
            <div className="items">
              <li>
                Jednoosobowy-{" "}
                <strong style={{ color: "#495453" }}>100zł/doba</strong>
              </li>
              <li>
                Dwuosobowy-{" "}
                <strong style={{ color: "#495453" }}>190zł/doba</strong>
              </li>
            </div>
          </div>
        </RoomBox>
        <RoomBox>
          <div className="left-col">
            <div>
              <div className="slide-container" autoplay={false}>
                <FadeSlide>
                  <div className="each-FadeSlide" autoplay={false}>
                    <div>
                      <StaticImage
                        src="../../assets/pokoje2/1_1.jpg"
                        alt="pokoj"
                      />
                    </div>
                  </div>
                  <div className="each-FadeSlide" autoplay={false}>
                    <div>
                      <StaticImage
                        src="../../assets/pokoje2/1_2.jpg"
                        alt="pokoj"
                      />
                    </div>
                  </div>
                </FadeSlide>
              </div>
            </div>
          </div>
          <div className="right-col">
            <h3>Pokój z łazienką zbiorczą na korytarzu</h3>

            <h3>Cena:</h3>
            <div className="items">
              <li>
                Jednoosobowy-{" "}
                <strong style={{ color: "#495453" }}>70zł/doba</strong>
              </li>
              <li>
                Dwuosobowy-{" "}
                <strong style={{ color: "#495453" }}>125zł/doba</strong>
              </li>
              <li>
                Trzyosobowy-{" "}
                <strong style={{ color: "#495453" }}>185zł/doba</strong>
              </li>
              <li>
                Czteroosobowy-{" "}
                <strong style={{ color: "#495453" }}>235zł/doba</strong>{" "}
              </li>
            </div>
          </div>
        </RoomBox>
      </div>
    </Wrapper>
  );
};

const CustomButton = styled.button`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  padding: 0 2rem;
  font-size: 0.75rem;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  border: 1px solid rgba(34, 44, 43, 0.15);
  color: #384140;
  background: transparent;
  cursor: pointer;
  transition: color 0.3s ease, border 0.3s ease, background-color 0.3s ease;
  margin-top: 20px;
`;

const RoomBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80vw;
  height: 438px;
  margin-bottom: 30px;
  box-shadow: 1px 2px 5px -1px rgb(0 0 0 / 60%);
  .slide-container {
    width: 100%;
  }
  @media (max-width: 1024px) {
    min-height: 438px;
    height: auto;
    width: 480px;
  }
  @media (max-width: 582px) {
    width: 80%;
  }
  button {
    width: 50%;
    height: 30px;
    text-transform: uppercase;
    font-weight: 700;
    @media (max-width: 640px) {
      width: 80%;
    }
  }
  @media (max-width: 1024px) {
    flex-direction: column;
  }
  img {
    height: 441px;
    @media (max-width: 1024px) {
      height: 360px;
    }
  }
  .right-col {
    margin: 20px;
    width: 50%;
    display: flex;
    justify-items: center;
    align-items: center;
    flex-direction: column;
    h3 {
      text-align: center;
    }
    @media (max-width: 1024px) {
      width: 80%;
    }
    .items {
      display: flex;
      flex-direction: column;
      justify-items: center;
      align-items: center;
      li {
        list-style-type: none;
        margin: 5px;
        svg {
          margin-right: 5px;
        }
      }
    }
  }
  .left-col {
    .react-slideshow-fade-wrapper {
      width: 580px;
    }
    width: 50%;

    @media (max-width: 1024px) {
      width: 100%;
    }
  }
`;

const Wrapper = styled.section`
  width: 100vw;
  min-height: 90vh;
  .container {
    width: 100vw;
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;

    .text_container {
      @media (max-width: 1024px) {
        padding: 80px 54px;
      }
      padding: 40px 288px;
      h2 {
        font-size: 40px;
        font-family: "Josefin Slab", serif;
        color: #495453;
        text-align: center;
      }
      p {
        font-size: 16px;
        text-align: center;
        color: #495453;
        line-height: 1.75;
        font-weight: 200;
        margin: 40px 180px;
        @media (max-width: 1024px) {
          margin: 40px 10px;
        }
      }
    }
  }
`;

const Header = styled.div`
  height: 55vh;
  background-image: url(${PokojeIMG});
  background-position: center;
  position: relative;
  background-size: cover;

  .text {
    width: 440px;
    position: absolute;
    bottom: 5vh;
    text-align: center;
    font-family: "Josefin Slab", serif;
    font-weight: 200;
    font-size: 80px;
    left: calc(50% - 220px);
    color: white;
    @media (max-width: 1024px) {
      font-size: 46px;
      width: 320px;
      left: calc(50% - 160px);
    }
  }
`;

const StickyBar = styled.div`
  width: 100vw;
  height: 60px;
  z-index: 12;
  box-shadow: 0px 1px 0px 0px rgb(34 44 43 / 15%);
  display: flex;
  justify-content: center;
  align-items: center;
  position: -webkit-sticky;
  position: sticky;
  top: 61px;
  left: 0;
  background-color: #fff;
  p {
    text-transform: uppercase;
    font-size: 13px;
    color: #919695;
    margin: 10px;
    a {
      text-decoration: none;
      color: #919695;
    }
    cursor: pointer;
  }
`;

export default MainSection;
