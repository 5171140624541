import React from "react";
import Layout from "../components/Layout";
import MainSection from "../components/WynajmijComponents/MainSection";
import { Helmet } from "react-helmet";

export default function Wynajmij() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Noclegi | Stragona</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <MainSection />
    </>
  );
}
